import React from 'react';
import { Link } from "gatsby"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    fontSize: "2.5rem",
    fontFamily: "Knewave",
    color: "#ffffff",
    paddingLeft: '12px',
    paddingBottom: '6px',
    fontWeight: 'bold',
    letterSpacing: "0.05em",
    textDecoration:'none'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
      maxWidth: '1000px',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
  },
  appBar: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'row'
  },
  iconLinks: {
      display: 'flex',
      alignSelf: 'center'
  }
}));

const siteName = "jayla + artur"

export default function TopAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
            <div className="toolbar-left">
                <Typography className={classes.title} variant="h6" noWrap>
                    <Link to="/" >
                        {siteName}
                    </Link>
                </Typography>
            </div>
            <div className="toolbar-right">
                <a className={classes.iconLinks} href="https://www.instagram.com/jaylaandartur">
                    <InstagramIcon className={classes.icons} fontSize='large'></InstagramIcon>
                    </a>
                <a className={classes.iconLinks} href="https://www.youtube.com/channel/UCLGsFDEYKml38Emz6b56uUg">
                    <YouTubeIcon className={classes.icons} fontSize='large' ></YouTubeIcon>
                </a>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>
            </div>
            <IconButton
                edge="start"
                className="toolbar-menu-icon"
                color="inherit"
                aria-label="open drawer"
            >
                <MenuIcon />
            </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}