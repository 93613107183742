import * as React from "react"
import { Link } from "gatsby"
import TopAppBar from "./appBar";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
  <div className="global-wrapper">
    <header className="top-bar">
      <TopAppBar />
    </header>
      <div className="content-wrapper" data-is-root-path={isRootPath}>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer>
      </div>
    </div>
  )
}

export default Layout
